<template>
  <div :class="animation">
    <b-overlay
      :opacity="0.8"
      :show="loadTransaction"
      variant="transparent"
      blur="3px"
      rounded="sm"
    >

      <b-card
        title="Conflit Crypto"
        sub-title="Résoudre un conflit de paiment (OVERPAID / UNDERPAID)"
      >
        <div
          v-if="pendingTransaction"
          class="text-primary"
        >
          <h4>
            CAS : <span class="text-danger">
              {{ pendingTransaction.crypto_context }}</span>
          </h4>
          <h5>
            Ticket : <span class="text-primary">
              {{ pendingTransaction.ticket }}</span>
          </h5>
        </div>
        <b-row>
          <b-col
            sm="6"
            class="p-1"
          >
            <v-select
              v-model="$v.selectedBuyCurrency.$model"
              :class="{
                'is-invalid': $v.selectedBuyCurrency.$error,
              }"
              :clearable="true"
              label="title"
              :options="buyCurrenciesOptions"
              disabled
            >
              <template #header>
                <div class="text-primary">
                  Je donne !!!</div>
              </template>
              <template v-slot:option="option">
                <img
                  :src="option.src"
                  class="mr-1 float-left"
                  width="45"
                  height="45"
                >
                <span>{{ option.data.libelle }}</span> <br>
                <span>{{ option.data.buy }} FCFA </span>
              </template>
            </v-select>
            <div
              v-if="$v.selectedBuyCurrency.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.selectedBuyCurrency.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-col>
          <b-col
            sm="6"
            class="p-1"
          >
            <v-select
              v-model="$v.selectedSellCurrency.$model"
              :class="{
                'is-invalid': $v.selectedSellCurrency.$error,
              }"
              :clearable="true"
              label="title"
              :options="sellCurrenciesOptions"
              disabled
            >
              <!-- :selectable="(option) => option.data.solde > 0" -->
              <template #header>
                <div class="text-primary">
                  Je reçois !!!</div>
              </template>
              <template v-slot:option="option">
                <img
                  :src="option.src"
                  class="mr-1 float-left"
                  width="45"
                  height="45"
                >
                <span>{{ option.data.libelle }}</span> <br>
                <span>{{ option.data.sale }} {{ checkFranc(option.data.dev_code) ? 'FCFA' : 'GNF' }} | SD: {{ option.data.solde ? option.data.solde : '0' }} {{ getCurrentCurrency(option.data) }}</span>
              </template>
            </v-select>
            <div
              v-if="$v.selectedSellCurrency.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.selectedSellCurrency.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-col>

          <b-col
            v-if="sellItem && buyItem"
            sm="6"
          >
            <b-form-group>
              <label>
                <span v-if="isDollar(buy)">
                  Montant reçu (<span>{{ checkCurrency(buyItem.data.dev_code) }}</span>)
                </span>
                <span v-else>
                  Montant reçu (<span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>)
                </span>
              </label>
              <b-form-input
                id="get-buy-fees"
                v-model.number="$v.sendAmount.$model"
                type="number"
                aria-describedby="get-buy-fees"
                :class="{
                  'is-invalid': $v.sendAmount.$error,
                  'is-valid': !$v.sendAmount.$invalid && buyItem.data.dev_code === 'OM_BURKINA',
                }"
              />
              <!-- <b-form-text
              v-if="!$v.sendAmount.$invalid && !isDollar(buy)"
              id="get-buy-fees"
            >
              <p class="text-primary">
                Frais réseau :  <span class="text-danger">{{ (getBuyFees ) > 0 ? ( getBuyFees) : 0 }} </span> <span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span></p>
            </b-form-text> -->
              <div
                v-if="$v.sendAmount.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.sendAmount.required">{{
                  $t("errors.required")
                }}<br></span>
                <span v-if="!$v.sendAmount.checkMin">
                  Le minimum à envoyer est
                  <span v-if="isDollar(buy) && buyItem.data.is_crypto">{{ minForCrypto }} $</span>
                  <span v-else-if="!isDollar(buy) && checkFranc(buyItem.data.dev_code) && sellItem.data.is_crypto">{{ minForFCFAtoCrypto }} XOF</span>
                  <span v-else-if="!isDollar(buy) && !checkFranc(buyItem.data.dev_code) && sellItem.data.is_crypto">{{ minForGNFtoCrypto }} GNF</span>
                  <span v-else-if="isDollar(buy)">{{ minForDollar }} $</span>
                  <span v-else>{{ minForFCFA }} {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                  <br></span>
                <span v-if="!$v.sendAmount.checkOrangeAmount">
                  Le montant à échanger doit se terminer par 0 ou par 5.
                </span>
              </div>
              <div
                v-if="!$v.sendAmount.$invalid && buyItem.data.dev_code === 'OM_BURKINA'"
                class="valid-feedback"
              >
                <span>Veuillez générer un OTP de {{ getBuyFees + sendAmount }} FCFA.</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem"
            sm="6"
          >
            <b-form-group>
              <label>
                <span v-if="isDollar(sell)">
                  Montant à envoyer (<span>{{ checkCurrency(sellItem.data.dev_code) }}</span>)
                </span>
                <span v-else>
                  Montant à envoyer (<span>{{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>)
                </span>
              </label>
              <b-form-input
                id="get-sale-fees"
                v-model.number="$v.getReceivedAmount.$model"
                type="number"
                :class="{
                  'is-invalid': $v.getReceivedAmount.$error,
                }"
                class="border-warning font-weight-bolder text-large"
                disabled
                aria-describedby="get-sale-fees"
              />
              <div
                v-if="$v.getReceivedAmount.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.getReceivedAmount.required">{{
                  $t("errors.required")
                }}</span>
                <span v-if="!$v.getReceivedAmount.checkMax">{{
                  `Le solde disponible est de `+ sellItem.data.solde
                }}</span>
              <!-- <span v-if="!$v.getReceivedAmount.minValue">
                Le montant minimum pour une transaction du système est
                <span v-if="isDollar(sell)">{{ minForDollar }} $</span>
                <span v-else>{{ minForFCFA }} {{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                <br>
              </span> -->
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(buy)"
            sm="6"
          >
            <b-form-group label="N° Téléphone d'envoi">
              <vue-tel-input
                v-model.trim="$v.senderAdress.$model"
                :class="{
                  'is-invalid': $v.senderAdress.$error,
                }"
                @validate="checkSendValidity($event)"
                @countryChanged="updateSender($event)"
              >
                <template #arrow-icon>
                  <span />
                </template>
              </vue-tel-input>
              <div
                v-if="$v.senderAdress.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.senderAdress.required">{{
                  $t("errors.required")
                }}<br></span>
                <span v-if="!$v.senderAdress.checkCorrectFormat">
                  Format invalide
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(buy) && showWaveBuyInfo"
            sm="6"
          >
            <b-form-group label="Nom complet de l'expéditeur">
              <b-form-input
                v-model="$v.senderAddressName.$model"
                :class="{
                  'is-invalid': $v.senderAddressName.$error,
                }"
                :formatter="formatter"
              />
              <div
                v-if="$v.senderAddressName.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.senderAddressName.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(buy) && showWaveBuyInfo"
            sm="6"
          >
            <b-form-group label="N° de paiment WAVE">
              <b-form-select
                v-model="$v.manuelPaymentAddress.$model"
                :options="addressOptions"
                :class="{
                  'is-invalid': $v.manuelPaymentAddress.$error,
                }"
              />
              <div
                v-if="$v.manuelPaymentAddress.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.manuelPaymentAddress.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && (sellItem.data.dev_code !== 'VISA')"
            sm="6"
          >
            <b-form-group
              :label="isDollar(sell) ? 'Adresse de réception' : 'N° Téléphone de réception'"
            >
              <template #description>
                <span
                  v-if="sellItem.data.is_crypto"
                  class="text-primary text-justify"
                >
                  Avis aux utilisateurs du Wallet Coinbase: <br>
                  Pour une réception instantanée de vos Bitcoin, Prière renseignez l'adresse email liée au Wallet coinbase.<br>
                  Renseignez votre adresse Bitcoin si vous n'utilisez pas un  Wallet coinbase
                </span>
              </template>
              <b-form-input
                v-if="isDollar(sell)"
                v-model="$v.receiveAddress.$model"
                :class="{
                  'is-invalid': $v.receiveAddress.$error,
                }"
                type="text"
              />
              <vue-tel-input
                v-else
                v-model.trim="$v.receiveAddress.$model"
                :class="{
                  'is-invalid': $v.receiveAddress.$error,
                }"
                disabled
                :default-country="getCountry(pendingTransaction.receivedCurrency)"
                @validate="checkReceivedValidity($event)"
                @countryChanged="updateSender($event)"
              >
                <template #arrow-icon>
                  <span />
                </template>
              </vue-tel-input>
              <div
                v-if="$v.receiveAddress.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.receiveAddress.required">{{
                  $t("errors.required")
                }}<br></span>
                <span v-if="!$v.receiveAddress.checkCorrectFormat">
                  Format invalide
                </span>
              </div>
            </b-form-group>

          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(sell) && (showVisaInfo || showWaveSellInfo)"
            sm="6"
          >
            <b-form-group :label="showWaveSellInfo ? 'Nom complet du receveur' : 'Nom complet du propriétaire de la carte'">
              <b-form-input
                v-model="$v.receiverAddressName.$model"
                :class="{
                  'is-invalid': $v.receiverAddressName.$error,
                }"
                :formatter="formatter"
              />
              <div
                v-if="$v.receiverAddressName.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.receiverAddressName.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(sell) && showVisaInfo"
            sm="6"
          >
            <b-form-group
              label="Quatre derniers chiffres du numéro de la carte"
              description="Les 4 derniers chiffres des 16 chiffres qui representent le numero de la carte"
            >
              <b-form-input
                v-model="$v.visaLastDigit.$model"
                :class="{
                  'is-invalid': $v.visaLastDigit.$error,
                }"
                type="number"
              />
              <div
                v-if="$v.visaLastDigit.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.visaLastDigit.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="sellItem && buyItem && !isDollar(sell) && showVisaInfo"
            sm="6"
          >
            <b-form-group
              label="Identifiant de la carte"
              description="Ce nombre se trouve derriere votre carte"
            >
              <b-form-input
                v-model="$v.visaCardId.$model"
                :class="{
                  'is-invalid': $v.visaCardId.$error,
                }"
                type="number"
              />
              <div
                v-if="$v.visaCardId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.visaCardId.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            v-if="sellItem && buyItem && buyItem.data.has_otp"
            sm="6"
          >
            <b-form-group
              label="OTP"
            >
              <b-form-input
                v-model="$v.otp.$model"
                type="number"
                :class="{
                  'is-invalid': $v.otp.$error,
                }"
              />
              <div
                v-if="$v.otp.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.otp.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
          >
            <b-form-group
              label="Message"
            >
              <b-form-textarea
                v-model="$v.message.$model"
                type="number"
                :class="{
                  'is-invalid': $v.message.$error,
                }"
              />
              <div
                v-if="$v.message.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.message.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
          >
            <!-- <div class="text-center">
            <b-spinner
              v-if="process"
              variant="primary"
            />
          </div> -->
            <div class="text-right mt-2">
              <b-button
                variant="outline-primary"
                :disabled="process"
                @click="tryToExchange"
              >
                <span>{{ $t("form.exchange") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-modal
      v-if="showModal"
      id="modalId"
      ref="modal"
      v-model="showModal"
      title="Récapitulatif"
      :no-close-on-backdrop="cryptoProcess || closeProcess || loaded"
      :no-close-on-esc="cryptoProcess || closeProcess || loaded"
      :hide-header-close="cryptoProcess || closeProcess || loaded"
      hide-footer
      centered
      @close="closeDetailsModal"
    >
      <b-row :class="animation">
        <b-col>
          <b-table-simple responsive>
            <tr>
              <td>Je donne</td>
              <td>
                {{ buyItem.data.libelle }}
              </td>
            </tr>
            <tr>
              <td>Je reçois</td>
              <td>
                {{ sellItem.data.libelle }}
              </td>
            </tr>
            <tr>
              <td>
                Montant à échanger
              </td>
              <td>
                <b-badge
                  variant="primary"
                  pill
                >
                  <span v-if="isDollar(buy)">
                    {{ sendAmount }} <span>{{ checkCurrency(buyItem.data.dev_code) }}</span>
                  </span>
                  <span v-else>
                    {{ sendAmount }} <span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                  </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="!isDollar(buy)">
              <td v-if="buyItem.data.dev_code === 'WAVE_SENEGAL' || buyItem.data.dev_code === 'WAVE_CI'">
                Frais WAVE</td>
              <td v-else>
                Frais réseau</td>
              <td>
                <b-badge
                  variant="warning"
                  pill
                >
                  {{ getBuyFees }} <span> {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="!isDollar(buy)">
              <td>Total à payer</td>
              <td>
                <b-badge
                  variant="danger"
                  pill
                >
                  {{ getBuyFees + sendAmount }} <span> {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="!isDollar(sell)">
              <td v-if="sellItem.data.dev_code === 'VISA'">
                Frais UBA</td>
              <td v-else-if="sellItem.data.dev_code === 'WAVE_SENEGAL' || sellItem.data.dev_code === 'WAVE_CI'">
                Frais total</td>
              <td v-else>
                Frais fixe</td>
              <td>
                <b-badge
                  variant="warning"
                  pill
                >
                  {{ getSaleFees + systemFees }} <span> {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr>
              <td>
                Montant à recevoir
              </td>
              <td>
                <b-badge
                  variant="primary"
                  pill
                >
                  <span v-if="isDollar(sell)">
                    {{ getReceivedAmount }} <span>{{ checkCurrency(sellItem.data.dev_code) }}</span>
                  </span>
                  <span v-else>
                    {{ getReceivedAmount }} <span>{{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                  </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="sender">
              <td>Adresse d'envoi</td>
              <td>
                {{ sender ? sender : senderAdress }}
              </td>
            </tr>
            <tr v-if="showWaveBuyInfo">
              <td>Expéditeur</td>
              <td>
                {{ senderAddressName }}
              </td>
            </tr>
            <tr v-if="showWaveBuyInfo">
              <td>N° de paiment WAVE</td>
              <td>
                <span
                  id="copy"
                  v-b-tooltip.hover.top="'Cliquer pour copier!'"
                  class="cursor"
                  @click="onCopy(sendManualAddressName)"
                >
                  <b-icon
                    class="text-primary"
                    icon="clipboard"
                    font-scale="1"
                  />
                  {{ sendManualAddressName }}
                </span>
              </td>
            </tr>
            <tr v-if="!showVisaInfo">
              <td>Adresse de réception</td>
              <td>
                {{ receiver ? receiver : receiveAddress }}
              </td>
            </tr>
            <tr v-if="showWaveSellInfo">
              <td>Receveur</td>
              <td>
                {{ receiverAddressName }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Propriétaire</td>
              <td>
                {{ receiverAddressName }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Identifiant de la carte</td>
              <td>
                {{ visaCardId }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Numero de Carte (4 derniers chiffres)</td>
              <td>
                {{ visaLastDigit }}
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>Montant a générer pour OTP</td>
              <td>
                {{ getBuyFees + sendAmount }}
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>OTP</td>
              <td>
                {{ otp }}
              </td>
            </tr>
            <tr>
              <td>Message</td>
              <td>
                {{ message }}
              </td>
            </tr>
          </b-table-simple>
          <p v-if="!isDollar(sell)">
            Les frais de transfert sont automatiquement appliqués
          </p>
          <b-form-checkbox
            id="checkbox-1"
            v-model="confirm"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            J'accepte les conditions d'échange de la plateforme
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            v-if="cryptoProcess || loaded || closeProcess"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Transaction en cours ...</p>
            </div>
          </div>

          <div
            class="mt-1"
          >
            <b-button
              :disabled="cryptoProcess || loaded || closeProcess || !confirm"
              class="form-control"
              variant="primary"
              @click="confirmExchange"
            >
              Confirmer le paiment
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import 'animate.css'
import { getPrivileges } from '@/auth/utils'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      animation: null,
      showReceiveButton: false,
      file: null,
      count: 0,
      interval: null,
      currentTicket: null,
      confirm: null,
      allCurrencies: [],
      loaded: false,
      receiver: null,
      selectedBuyCurrency: null,
      selectedSellCurrency: null,
      showModal: false,
      sentPaymentId: null,
      showBuyAddress: false,
      manuelPaymentAddress: null,
      sendManualAddressName: null,
      currentTransaction: null,
      pendingTransaction: null,
      loadTransaction: false,
      showSellAdress: false,
      buy: null,
      buyItem: null,
      sell: null,
      sellItem: null,
      sendAmount: null,
      senderAdress: null,
      message: null,
      sender: null,
      receiveAmount: null,
      receiveAddress: null,
      showWaveBuyInfo: null,
      showWaveSellInfo: null,
      showVisaInfo: null,
      visaLastDigit: null,
      visaCardId: null,
      senderAddressName: null,
      receiverAddressName: null,
      countSent: 0,
      sendOptions: [],
      addresses: [],
      fetchedOptions: [],
      sellCurrencies: [],
      buyCurrencies: [],
      accounts: [],
      balances: [],
      countries: ['BF', 'CM', 'CI', 'GN', 'ML', 'SN'],
      minForDollar: 2,
      minForCrypto: 5,
      minForFCFA: 1000,
      minForFCFAtoCrypto: 10000,
      minForGNFtoCrypto: 125000,
      translations: {
        countrySelectorLabel: 'Code',
        countrySelectorError: 'Pays',
        phoneNumberLabel: 'N°',
        example: 'Ex :',
      },
      phoneNumber: null,
      otp: null,
      cash_in_fees: 0,
      cash_out_fees: 0,
      applyFees: false,
      loadImage: false,
      progress: 0,
      currentPath: null,
      fees_system: 0,
      isValidSender: false,
      isValidReceiver: false,
      numberOfValidTransaction: 0,
    }
  },
  validations: {
    message: {
      required,
    },
    selectedBuyCurrency: {
      required,
    },
    selectedSellCurrency: {
      required,
    },
    visaLastDigit: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showVisaInfo) {
          return true
        }
        return false
      }),
      checkSize() {
        if (this.$v.visaLastDigit.$required) {
          if (this.visaLastDigit.length === 4) return true
        }
        return ' '
      },
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    visaCardId: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showVisaInfo) {
          return true
        }
        return false
      }),
    },
    senderAddressName: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveBuyInfo) {
          return true
        }
        return false
      }),
    },
    receiverAddressName: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveSellInfo || this.showVisaInfo) {
          return true
        }
        return false
      }),
    },
    manuelPaymentAddress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveBuyInfo) {
          return true
        }
        return false
      }),
    },
    file: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.buy) && !this.buyItem.data.is_auto) {
          return true
        }
        return false
      }),
      checkSize() {
        if (this.file) {
          const filesize = this.file.size
          const file = Math.round(filesize / 1024 / 1024)
          if (file <= 5) {
            return true
          }
          return false
        }
        return true
      },
    },
    getReceivedAmount: {
      required,
      // eslint-disable-next-line func-names
      checkMax() {
        if ((this.getReceivedAmount) < this.sellItem.data.solde) {
          return true
        }
        return false
      },
      // minValue() {
      //   if (this.getReceivedAmount >= this.minForDollar) {
      //     return true
      //   } if (this.getReceivedAmount >= this.minForFCFA) {
      //     return true
      //   }
      //   return false
      // },
    },
    cash_in_fees: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.buy)) {
          return true
        }
        return false
      }),
    },
    cash_out_fees: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.sell)) {
          return true
        }
        return false
      }),
    },
    sendAmount: {
      required,
      // eslint-disable-next-line no-undef
      checkMin() {
        if (this.sendAmount) {
          if (this.isDollar(this.buy) && this.sendAmount < this.minForDollar) {
            return false
          } if (!this.isDollar(this.buy) && this.sendAmount < this.minForFCFA) {
            return false
          } if (this.isDollar(this.buy) && this.buyItem.data.is_crypto && this.sendAmount < this.minForCrypto) {
            return false
          } if (!this.isDollar(this.buy) && this.checkFranc(this.buyItem.data.dev_code) && this.sellItem.data.is_crypto && this.sendAmount < this.minForFCFAtoCrypto) {
            return false
          } if (!this.isDollar(this.buy) && !this.checkFranc(this.buyItem.data.dev_code) && this.sellItem.data.is_crypto && this.sendAmount < this.minForGNFtoCrypto) {
            return false
          }
          return true
        }
        return ' '
      },
      checkOrangeAmount() {
        if (this.sendAmount) {
          if (this.checkOrange(this.buyItem.data.dev_code)) {
            if (this.buyItem.data.dev_code === 'OM_GUINEE') {
              if (this.sendAmount % 100 === 0) {
                return true
              }
              return false
            }
            if (this.sendAmount % 5 === 0) {
              return true
            }
            return false
          }
        }
        return ' '
      },
    },
    receiveAddress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.showVisaInfo) {
          return true
        }
        return false
      }),
      checkCorrectFormat() {
        if (!this.isDollar(this.sell)) {
          if (this.isValidReceiver) {
            return true
          }
          return false
        }
        return true
      },
    },
    senderAdress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.buy)) {
          return true
        }
        return false
      }),
      checkCorrectFormat() {
        if (!this.isDollar(this.buy)) {
          if (this.isValidSender) {
            return true
          }
          return false
        }
        return true
      },
    },
    otp: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.buyItem.data.has_otp) {
          return true
        }
        return false
      }),
    },
  },
  computed: {
    ...mapGetters({
      success: 'exchangeSuccess',
      error: 'exchangeError',
      process: 'exchangeProcess',

      closeSuccess: 'closeExchangeSuccess',
      closeError: 'closeExchangeError',
      closeProcess: 'closeExchangeProcess',

      cryptoSuccess: 'closeCryptoSuccess',
      cryptoError: 'closeCryptoError',
      cryptoProcess: 'closeCryptoProcess',
    }),
    systemFees() {
      let fees = 0
      if (!this.isDollar(this.buy) && !this.isDollar(this.sell)) {
        // GNF a FCFA
        if (!this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          // convertir GNF en FCFA
          let converted = 0
          if (!this.buyItem.data.is_auto) {
            converted = (this.sendAmount - this.getBuyFees) * this.buyItem.data.buy
          } else {
            converted = this.sendAmount * this.buyItem.data.buy
          }
          if (!this.sellItem.data.is_auto) {
            fees = 0
          } else {
            fees = converted * 0.015
          }
        }
        // FCFA a FCFA
        if (this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          if (this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
            fees = this.sendAmount * 0.015
          } else if (this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
            fees = this.sendAmount * 0.015
          } else if (!this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
            fees = this.sendAmount * 0.015
          } else if (!this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
            fees = 0
          }
        }
      }
      return fees
    },
    addressOptions() {
      const data = [
        {
          value: null,
          text: 'Choisir le numéro',
        },
      ]
      if (this.addresses.length > 0) {
        this.addresses.forEach(item => {
          const current = {
            text: item.address,
            value: item.id,
          }
          data.push(current)
        })
      }
      return data
    },
    sellOptions() {
      const data = []
      this.allCurrencies.forEach(element => {
        if (element.sale_status) {
          data.push(element)
        }
      })
      return data
    },
    buyOptions() {
      const data = []
      this.allCurrencies.forEach(element => {
        if (element.buy_status && element.dev_code !== 'visa') {
          data.push(element)
        }
      })
      return data
    },
    sellCurrenciesOptions() {
      const data = []
      this.sellOptions.forEach(item => {
        if (this.buy && item.id !== this.buy) {
          const current = {
            id: item.id,
            title: item.libelle,
            code: item.dev_code,
            data: item,
            src: item.icones,
          }
          data.push(current)
        }
      })
      return data
    },
    buyCurrenciesOptions() {
      const data = []

      this.buyOptions.forEach(item => {
        const current = {
          id: item.id,
          title: item.libelle,
          code: item.dev_code,
          data: item,
          src: item.icones,
        }
        data.push(current)
      })
      return data
    },
    mobilesOptions() {
      const data = []
      if (this.allCurrencies.length > 0) {
        this.allCurrencies.map(item => {
          if (item.ismobile) {
            data.push(item)
          }
          return data
        })
      }
      return data
    },
    getBuyFees() {
      let value = null
      // Si c'est du dolalr
      if (this.isDollar(this.buy)) {
        value = this.buyItem.data.partner_fees_out
      }
      // Si c'est mobile
      if (this.buyItem.data.ismobile) {
        // Si le fees est un pourcentage
        if (this.buyItem.data.partner_fees_out < 1) {
          const fees = (this.sendAmount / (1 - this.buyItem.data.partner_fees_out)) - this.sendAmount
          if (this.checkOrange(this.buyItem.data.dev_code)) {
            if (this.buyItem.data.dev_code === 'OM_GUINEE') {
              value = this.round100(this.roundToUp(fees))
            }
            value = this.round5(this.roundToUp(fees))
          } else {
            value = this.roundToUp(fees)
          }
        } else {
          // Si le fees est constant
          value = this.buyItem.data.partner_fees_out
        }
      }
      // Si cest un moyen manuel
      if (!this.buyItem.data.is_auto) {
        value = this.sendAmount * this.buyItem.data.partner_fees_out
      }
      return value
    },
    getSaleFees() {
      if (this.$v.sendAmount.$touch && !this.$v.sendAmount.$anyError) {
        const sum = this.sendAmount * this.buyItem.data.buy
        let fees = 0
        if (this.sellItem.data.partner_fees_in < 1) {
          if (this.checkOrange(this.sellItem.data.dev_code)) {
            if (this.buyItem.data.dev_code === 'OM_GUINEE') {
              fees = this.round100(this.roundToUp(sum * this.sellItem.data.partner_fees_in))
            } else {
              fees = this.round5(this.roundToUp(sum * this.sellItem.data.partner_fees_in))
            }
          }
          if (!this.sellItem.data.is_auto) {
            fees = sum * this.sellItem.data.partner_fees_in
          } else {
            fees = this.roundToUp(sum * this.sellItem.data.partner_fees_in)
          }
        } else {
          fees = this.sellItem.data.partner_fees_in
        }
        return fees
      }
      return 0
    },
    getReceivedAmount() {
      let value = 0
      if (this.$v.sendAmount.$touch && !this.$v.sendAmount.$anyError) {
        // $ to $
        if (this.isDollar(this.buy) && this.isDollar(this.sell)) {
          value = (this.sendAmount * this.buyItem.data.buy) / this.sellItem.data.sale
        }
        // $ to FCFA
        if (this.isDollar(this.buy) && !this.isDollar(this.sell)) {
          // $ to FCFA
          if (this.checkFranc(this.sellItem.data.dev_code)) {
            const sum = this.sendAmount * this.buyItem.data.buy
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = sum * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = sum - fees
          }
          // $ to GNF
          if (!this.checkFranc(this.sellItem.data.dev_code)) {
            const sum = (this.sendAmount * this.buyItem.data.buy) / this.sellItem.data.sale
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = sum * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = sum - fees
          }
        }
        // FCFA to $
        if (!this.isDollar(this.buy) && this.isDollar(this.sell)) {
          let realValue = 0
          if (!this.buyItem.data.is_auto) {
            realValue = this.sendAmount
          } else {
            realValue = this.checkFranc(this.buyItem.data.dev_code) ? this.sendAmount : this.sendAmount * this.buyItem.data.buy
          }
          value = realValue / this.sellItem.data.sale
        }
        // FCFA to FCFA
        if (!this.isDollar(this.buy) && !this.isDollar(this.sell)) {
          // FCFA a GNF
          if (this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
            // CONVERTIR FCFA en GNF
            let converted = 0
            let realValue = 0
            if (!this.buyItem.data.is_auto) {
              converted = (this.sendAmount - this.getBuyFees) / this.sellItem.data.sale
            } else {
              converted = this.sendAmount / this.sellItem.data.sale
            }
            if (!this.sellItem.data.is_auto) {
              realValue = converted
            } else {
              realValue = converted - (converted * 0.015)
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // GNF a FCFA
          if (!this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
            // convertir GNF en FCFA
            let converted = 0
            let realValue = 0
            if (!this.buyItem.data.is_auto) {
              converted = (this.sendAmount - this.getBuyFees) * this.buyItem.data.buy
            } else {
              converted = this.sendAmount * this.buyItem.data.buy
            }
            if (!this.sellItem.data.is_auto) {
              realValue = converted
            } else {
              realValue = converted - (converted * 0.015)
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // GNF a GNF
          if (!this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
            const realValue = this.sendAmount - (this.sendAmount * 0.015)
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // FCFA a FCFA
          if (this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
            let realValue = 0
            let converted = 0
            if (this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
              converted = this.sendAmount
              realValue = converted - (converted * 0.015)
            } else if (this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
              converted = this.sendAmount
              realValue = converted - (converted * 0.015)
            } else if (!this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
              converted = this.sendAmount
              realValue = converted - (converted * 0.015)
            } else if (!this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
              converted = this.sendAmount
              realValue = converted
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else if (this.sellItem.data.dev_code === 'VISA') {
              if (realValue > 0 && realValue <= 55000) {
                fees = this.sellItem.data.partner_fees_in
              } else if (realValue >= 55005) {
                fees = realValue * 0.02
              }
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
        }
      }
      let real = 0
      if (!this.isDollar(this.sell)) {
        if (this.sellItem.data.dev_code === 'OM_GUINEE') {
          real = this.round100(value)
        } else {
          real = this.round5(value)
        }
      } else {
        real = value
      }
      // eslint-disable-next-line no-nested-ternary
      return real > 0 ? this.isDollar(this.sell) === true ? real.toFixed(5) : Math.trunc(real.toFixed(5)) : 0
    },
    realReceivedAmount() {
      return this.getReceivedAmount - this.getSaleFees
    },
  },
  watch: {
    count() {
      if (this.count === 6) {
        this.loaded = false
        this.clear()
        this.closeDetailsModal()
        this.resetForm()
        this.correctRedirect()
      }
    },
    countSent() {
      if (this.countSent === 6) {
        this.loaded = false
        this.clear()
        this.closeDetailsModal()
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation',
            icon: 'AlertCircleIcon',
            text: "Veuillez vérifier l'état du paiment dans votre historique.",
            variant: 'warning',
          },
        })
        this.correctRedirect()
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.getAllCurrencies()
      },
    },
    currentTransaction(val) {
      if (val) {
        const response = val.data
        switch (response.sentState) {
          case 'PENDING':
            this.countSent += 1
            if (this.countSent < 6) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Ticket ${response.ticket}`,
                  icon: 'AlertCircleIcon',
                  text: 'Veuillez confirmer le paiement depuis votre telephone.',
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              })
            }
            break
          case 'INITIATED':
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${response.ticket}`,
                icon: 'AlertCircleIcon',
                text: 'Paiment initié, veuillez valider.',
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'FAILED':
            this.loaded = false
            this.closeDetailsModal()
            this.clear()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${response.ticket}`,
                icon: 'XCircleIcon',
                text: "Paiment non effectué. Vous n'avez pas validé la transaction.",
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'SUCCESSFUL':
            switch (response.receivedState) {
              case 'SUCCESSFUL':
                this.loaded = false
                this.clear()
                this.closeDetailsModal()
                this.resetForm()
                this.correctRedirect()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'CheckCircleIcon',
                    text: 'Montant envoyé avec succes.',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'MANUAL_WAITING':
                this.loaded = false
                this.clear()
                this.redirectOnHistory(response.state)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Paiment Manuel',
                    icon: 'CheckCircleIcon',
                    text: `${val.data.message}. Cette opération peut prendre quelques minutes (5 à 30 min).`,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'WAITING':
                this.tryToCloseExchange(response.id)
                break
              case 'PENDING':
                this.count += 1
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'AlertCircleIcon',
                    text: 'Paiment en cours. Veuillez verifier la transaction avec le ticket',
                    variant: 'warning',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'FAILED':
                this.loaded = false
                this.closeDetailsModal()
                this.clear()
                this.resetForm()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'XCircleIcon',
                    text: 'Une erreur est survenue lors du tranfert. Veuillez nous contacter via le support.',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              default:
                break
            }
            break
          default:
            break
        }
      }
    },
    manuelPaymentAddress(val) {
      if (val) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item in this.addresses) {
          if (this.addresses[item].id === val) {
            this.sendManualAddressName = this.addresses[item].address
          }
        }
      }
    },
    selectedBuyCurrency(val) {
      if (val) {
        this.clearValue()
        if (!val.data.is_auto && val.data.dev_code.split('_')[0] === 'WAVE') {
          this.showWaveBuyInfo = true
          this.getPaymentAddress(val.data.id)
        } else {
          this.showWaveBuyInfo = false
        }
        this.buy = val.id
        this.buyItem = val
        this.cash_in_fees = val.data.partner_fees_in
      }
    },
    selectedSellCurrency(val) {
      if (val) {
        if (!val.data.is_auto && val.data.dev_code.split('_')[0] === 'WAVE') {
          this.showWaveSellInfo = true
        } else {
          this.showWaveSellInfo = false
        }
        if (!val.data.is_auto && val.data.dev_code === 'VISA') {
          this.showVisaInfo = true
        } else {
          this.visaCardId = null
          this.visaLastDigit = null
          this.showVisaInfo = false
        }
        this.sell = val.id
        this.sellItem = val
        this.cash_out_fees = val.data.partner_fees_out
        this.receiveAdress = null
        this.receiver = null
      }
    },
    success(val) {
      if (val) {
        if (this.isDollar(this.buy)) {
          window.location = val.request.responseURL
        } else if (this.buyItem.data.dev_code === 'OM_CI') {
          window.location = val.data.payment_url
        } else if (!this.buyItem.data.is_auto) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Paiment Manuel',
              icon: 'CheckCircleIcon',
              text: `${val.data.message}. Cette opération peut prendre quelques minutes (5 à 30 min) pour le traitment.`,
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.redirectOnHistory(val.data.data.state)
        } else if (this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
          this.loaded = true
          const response = val.data
          // Verifier l'etat de la premiere transaction
          switch (response.data.sentState) {
            case 'SUCCESSFUL':
              // Verifier l'etat de la seconde transaction
              this.clear()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Paiment Manuel',
                  icon: 'CheckCircleIcon',
                  text: `${val.data.message}. Cette opération peut prendre quelques minutes (5 à 15min).`,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
              this.redirectOnHistory(val.data.data.state)
              break
            case 'PENDING':
            case 'INITIATED':
              this.interval = setInterval(() => {
                this.getCurrentTicket(response.data.id)
              }, 10000)
              break
            case 'FAILED':
              this.clear()
              this.closeDetailsModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ticket',
                  icon: 'XCircleIcon',
                  text: "Une erreur est survenue lors de l'initiation de la transaction. Veuillez rééssayer",
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              })
              break
            default:
              break
          }
        } else if ((this.buyItem.data.is_auto) && (this.sellItem.data.is_auto)) {
          this.loaded = true
          const response = val.data
          // Verifier l'etat de la premiere transaction
          switch (response.data.sentState) {
            case 'SUCCESSFUL':
              // Verifier l'etat de la seconde transaction
              switch (response.data.receivedState) {
                case 'SUCCESSFUL':
                  this.loaded = false
                  this.clear()
                  this.closeDetailsModal()
                  this.resetForm()
                  this.correctRedirect()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Ticket ${response.ticket}`,
                      icon: 'CheckCircleIcon',
                      text: 'Montant envoyé avec succes.',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  })
                  break
                case 'WAITING':
                  this.tryToCloseExchange(response.data.id)
                  break
                case 'PENDING':
                  this.getCurrentTicket(response.data.id)
                  break
                case 'FAILED':
                  this.loaded = false
                  this.clear()
                  this.closeDetailsModal()
                  this.resetForm()
                  this.correctRedirect()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Echange',
                      icon: 'XCircleIcon',
                      text: "Dépôt non réussi. Veuillez contacter l'administrateur.",
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  })
                  break
                default:
                  break
              }
              break
            case 'PENDING':
            case 'INITIATED':
              this.interval = setInterval(() => {
                this.getCurrentTicket(response.data.id)
              }, 10000)
              break
            case 'FAILED':
              this.clear()
              this.closeDetailsModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ticket',
                  icon: 'XCircleIcon',
                  text: "Une erreur est survenue lors de l'initiation de la transaction. Veuillez rééssayer",
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              })
              break
            default:
              break
          }
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    cryptoSuccess(val) {
      if (val) {
        this.loaded = true
        const response = val.data
        // Verifier l'etat de la premiere transaction
        switch (response.data.sentState) {
          case 'SUCCESSFUL':
            // Verifier l'etat de la seconde transaction
            switch (response.data.receivedState) {
              case 'PENDING':
              case 'SUCCESSFUL':
                this.loaded = false
                this.clear()
                this.closeDetailsModal()
                this.resetForm()
                this.correctRedirect()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'CheckCircleIcon',
                    text: 'Montant envoyé avec succes.',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'FAILED':
                this.loaded = false
                this.clear()
                this.closeDetailsModal()
                this.resetForm()
                this.correctRedirect()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Echange',
                    icon: 'XCircleIcon',
                    text: "Dépôt non réussi. Veuillez contacter l'administrateur.",
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              default:
                break
            }
            break
          case 'PENDING':
          case 'INITIATED':
            this.interval = setInterval(() => {
              this.getCurrentTicket(response.data.id)
            }, 10000)
            break
          case 'FAILED':
            this.clear()
            this.closeDetailsModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'XCircleIcon',
                text: "Une erreur est survenue lors de l'initiation de la transaction. Veuillez rééssayer",
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
            break
          default:
            break
        }
      }
    },
    cryptoError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    closeSuccess(val) {
      if (val) {
        const res = val.data
        switch (res.data.receivedState) {
          case 'PENDING':
            this.getCurrentTicket(res.data.id)
            break
          case 'SUCCESSFUL':
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${res.data.ticket}`,
                icon: 'CheckCircleIcon',
                text: 'CASHIN effectuee avec succes.',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'FAILED':
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${res.data.ticket}`,
                icon: 'XCircleIcon',
                text: 'CASHIN erreur.',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
            break
          default:
            break
        }
      }
    },
    closeError() {
    },
  },
  mounted() {
    this.getCurrentTransaction()
  },
  methods: {
    ...mapActions(['makeExchange', 'closeMobileExchange', 'resolveCryptoExchange']),
    getCountry(item) {
      return item.ismobile ? item.sigle.split(' ')[1] : ''
    },
    async getCurrentTransaction() {
      this.loadTransaction = true
      this.pendingTransaction = await this
        .$http
        .get(`/find-all-by-ticket/${this.$route.params.ticket}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loadTransaction = false
          const { data } = res
          // eslint-disable-next-line prefer-destructuring
          this.selectedBuyCurrency = this.buyCurrenciesOptions.filter(item => item.code === data.sentDevCode)[0]
          setTimeout(() => {
            // eslint-disable-next-line prefer-destructuring
            this.selectedSellCurrency = this.sellCurrenciesOptions.filter(item => item.code === data.receivedDevCode)[0]
          }, 500)
          setTimeout(() => {
            this.sendAmount = data.realSentValue
            this.receiveAddress = data.receiverAddress
          }, 500)
          return data
        })
        .catch(() => {
          this.loadTransaction = false
          return null
        })
    },
    confirmExchange() {
      this.resolveCryptoExchange({
        id: this.pendingTransaction.id,
        message: this.message,
        realReceivedValue: this.getReceivedAmount,
      })
    },
    async countExhange() {
      this.numberOfValidTransaction = await this.$http
        .get(`/stats-by-state-and-owner/${this.$store.getters.currentUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          let number = 0
          if (data.length > 0) {
            data.forEach(element => {
              if (element.state === 'SUCCESSFUL') {
                number = Number(element.data)
              }
            })
          }
          return number
        })
        .catch(() => [])
    },
    checkSendValidity(item) {
      if (item.valid) {
        this.sender = item.nationalNumber
        this.isValidSender = true
      } else {
        this.isValidSender = false
        this.sender = null
      }
    },
    checkReceivedValidity(item) {
      if (item.valid) {
        this.receiver = item.nationalNumber
        this.isValidReceiver = true
      } else {
        this.receiver = null
        this.isValidReceiver = false
      }
    },
    updateSender() {
      this.senderAddress = null
      this.isValidSender = false
    },
    updateReceiver() {
      this.receiverAddress = null
      this.isValidReceiver = false
    },
    onCopy(item) {
      const container = document.getElementById('copy')
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'N° de Paiment Wave',
            icon: 'CheckSquareIcon',
            text: `${item} Copié avec succès.`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'N° de Paiment Wave',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    tryToExchange() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'

        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.openDetailsModal()
    },
    formatter(value) {
      return value.toUpperCase()
    },
    getCurrentCurrency(item) {
      let currency = null
      if (this.isDollar(item.id)) {
        currency = this.checkCurrency(item.dev_code)
      } else {
        currency = this.checkFranc(item.dev_code) ? 'FCFA' : 'GNF'
      }
      return currency
    },
    async uploadFile(event) {
      [this.file] = event.target.files
    },
    round5(x) {
      return Math.floor(x / 5) * 5
    },
    round100(x) {
      return Math.floor(x / 100) * 100
    },
    checkOrange(item) {
      if (item.split('_')[0] === 'OM') return true
      return false
    },
    correctRedirect() {
      const path = getPrivileges() === 'client' ? 'client-history' : 'admin-history'
      this.$router.push({ name: path })
    },
    redirectOnHistory(item) {
      const path = getPrivileges() === 'client' ? '/history' : '/admin-history'
      this.$router.push(`${path}?state=${item}`)
    },
    kycRedirect() {
      const path = getPrivileges() === 'client' ? '/client-kyc-submit' : '/admin-kyc-submit'
      this.$router.push(path)
    },
    clear() {
      clearInterval(this.interval)
    },
    totalReceiveAmount() {
      return 0
    },
    roundToUp(item) {
      return Math.trunc(item) + 1
    },
    amountRound(item) {
      return Math.trunc(item)
    },
    async getCurrentTicket(item) {
      this.loaded = true
      this.currentTransaction = await this
        .$http
        .get(`/check-merchant-payment-status-in-touch/${item}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data)
        .catch(() => null)
    },
    resetForm() {
      this.selectedBuyCurrency = null
      this.selectedSellCurrency = null
      this.sendAmount = null
      this.senderAdress = null
      this.receiver = null
      this.receiveAddress = null
      this.sender = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    clearValue() {
      this.selectedSellCurrency = null
      this.sell = null
      this.sellItem = null
      this.sendAmount = null
      this.receiver = null
      this.receiveAddress = null
      this.sender = null
      this.senderAdress = null
      this.showWaveBuyInfo = null
      this.showWaveSellInfo = null
      this.showVisaInfo = null
      this.visaLastDigit = null
      this.visaCardId = null
      this.senderAddressName = null
      this.receiverAddressName = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    update(event) {
      if (event) {
        this.receiver = event.nationalNumber
      }
    },
    isDollar(checkedValue) {
      if (this.buy) {
        if (this.sellOptions.filter(item => item.id === checkedValue)[0].dev_code === 'VISA') {
          return false
        }
        const index = this.mobilesOptions.findIndex(item => item.id === checkedValue)
        if (index === -1) {
          return true
        }
        return false
      }
      return null
    },
    checkFranc(item) {
      if (item.split('_')[1] === 'GUINEE') {
        return false
      }
      return true
    },
    checkCurrency(item) {
      if (item.split('_')[1] === 'EURO') {
        return '€'
      }
      return '$'
    },
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data)
        .catch(() => [])
    },
    async getPaymentAddress(id) {
      this.addresses = await this.$http
        .get(`/tarif-receipt-address/find-by-tarif/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res.data
          return data.filter(item => Number(item.state) === 1)
        })
        .catch(() => [])
    },
    openDetailsModal() {
      this.showModal = true
    },
    closeDetailsModal() {
      this.showModal = false
      this.file = null
      this.confirm = null
    },
    tryToCloseExchange(item) {
      this.closeMobileExchange(item)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

  .cursor {
    cursor: pointer;
  }

  [dir=ltr] .vti__input {
    border-radius: 0 2px 2px 0;
    padding: 0.438rem 1rem;
    padding-left: 7px;
    background: transparent;
  }
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
